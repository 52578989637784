import React, { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  createHomeAddress,
  fetchHomeAddresses,
  updateHomeAddress,
  deleteHomeAddress,
} from "@/services/customerService";

type Address = {
  id: string;
  name: string;
  postal_code: string;
  address_line1: string;
  address_line2?: string;
  phone_number: string;
  is_default: boolean;
};

const HomeAddressPage: React.FC = () => {
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [editingAddress, setEditingAddress] = useState<Address | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const fetchedAddresses = await fetchHomeAddresses();
        setAddresses(fetchedAddresses);
      } catch (err: any) {
        setError("住所の取得に失敗しました。");
      }
    };
    fetchAddresses();
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage(null);

    const payload = {
      name: e.target["name"].value,
      postal_code: e.target["postalCode"].value,
      address_line1: e.target["addressLine1"].value,
      address_line2: e.target["addressLine2"].value || "",
      phone_number: e.target["phoneNumber"].value,
    };

    try {
      if (editingAddress) {
        await updateHomeAddress(editingAddress.id, payload);
        setAddresses((prev) =>
          prev.map((address) => (address.id === editingAddress.id ? { ...address, ...payload } : address))
        );
        setEditingAddress(null);
        setSuccessMessage("住所が更新されました！");
      } else {
        const newAddress = await createHomeAddress(payload);
        setAddresses((prev) => [...prev, newAddress]);
        setSuccessMessage("住所が登録されました！");
      }
    } catch (err: any) {
      setError("住所の登録/更新に失敗しました。");
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteHomeAddress(id);
      setAddresses((prev) => prev.filter((address) => address.id !== id));
      setSuccessMessage("住所が削除されました！");
    } catch (err: any) {
      setError("住所の削除に失敗しました。");
    }
  };

  const handleSetDefault = async (id: string) => {
    try {
      // API エンドポイントを呼び出してデフォルトを更新
      await updateHomeAddress(id, { is_default: true });

      // 他の住所の `is_default` を false にし、指定した住所を true に設定
      setAddresses((prev) =>
                   prev.map((address) => ({
                     ...address,
                     is_default: address.id === id,
                   }))
                  );

                  setSuccessMessage("デフォルトの住所が変更されました！");
    } catch (err: any) {
      setError("デフォルトの変更に失敗しました。");
    }
  };

  return (
    <section className="max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-md my-16">
      <h1 className="text-2xl font-bold text-gray-800">住所管理</h1>
      {error && <p className="mt-2 text-red-500">{error}</p>}
      {successMessage && <p className="mt-2 text-green-500">{successMessage}</p>}

      <form onSubmit={handleSubmit} className="mt-4 space-y-4">
        <div>
          <Label htmlFor="name">名前</Label>
          <Input
            id="name"
            type="text"
            defaultValue={editingAddress?.name || ""}
            placeholder="氏名"
            required
          />
        </div>
        <div>
          <Label htmlFor="postalCode">郵便番号</Label>
          <Input
            id="postalCode"
            type="text"
            defaultValue={editingAddress?.postal_code || ""}
            placeholder="123-4567"
            required
          />
        </div>
        <div>
          <Label htmlFor="addressLine1">住所1</Label>
          <Input
            id="addressLine1"
            type="text"
            defaultValue={editingAddress?.address_line1 || ""}
            placeholder="東京都新宿区西新宿1-1-1"
            required
          />
        </div>
        <div>
          <Label htmlFor="addressLine2">住所2</Label>
          <Input
            id="addressLine2"
            type="text"
            defaultValue={editingAddress?.address_line2 || ""}
            placeholder="マンション名や部屋番号（任意）"
          />
        </div>
        <div>
          <Label htmlFor="phoneNumber">電話番号</Label>
          <Input
            id="phoneNumber"
            type="text"
            defaultValue={editingAddress?.phone_number || ""}
            placeholder="090-1234-5678"
            required
          />
        </div>
        <Button type="submit" className="w-full bg-yellow-400 hover:bg-yellow-500 text-black font-bold">
          {editingAddress ? "更新" : "登録"}
        </Button>
      </form>

      <div className="mt-6">
        {addresses.map((address) => (
          <div
            key={address.id}
            className={`p-4 mb-4 rounded border ${
              address.is_default ? "border-green-500" : "border-gray-300"
            }`}
          >
            <h2 className="font-bold text-lg">{address.name}</h2>
            <p>{address.postal_code}</p>
            <p>{address.address_line1}</p>
            <p>{address.address_line2}</p>
            <p>{address.phone_number}</p>
            <div className="mt-2 flex space-x-2">
              {!address.is_default && (
                <Button
                  className="bg-green-400 hover:bg-green-500 text-white"
                  onClick={() => handleSetDefault(address.id)}
                >
                  デフォルトに設定
                </Button>
              )}
              <Button
                className="bg-blue-400 hover:bg-blue-500 text-white"
                onClick={() => setEditingAddress(address)}
              >
                編集
              </Button>
              <Button
                className="bg-red-400 hover:bg-red-500 text-white"
                onClick={() => handleDelete(address.id)}
              >
                削除
              </Button>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default HomeAddressPage;
