import axios from "axios";

const apiClient = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL || "http://api.joybase.jp/api",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

// トークンの取得関数
const getToken = () => localStorage.getItem("authToken");

// リフレッシュトークンの取得関数
const getRefreshToken = () => localStorage.getItem("refreshToken");

// リクエスト時にトークンを追加
apiClient.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// レスポンス時にトークン期限切れやエラーを確認
apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      const errorData = error.response.data;

      if (errorData.error === "Authorization header is missing") {
        // リフレッシュトークンを使って新しいトークンを取得
        const refreshToken = getRefreshToken();
        if (refreshToken) {
          try {
            const refreshResponse = await axios.post("http://api.joybase.localhost:5000/api/auth/refresh", {
              refresh_token: refreshToken,
            });
            const { authToken, refreshToken: newRefreshToken } = refreshResponse.data;

            // トークンを保存
            localStorage.setItem("authToken", authToken);
            localStorage.setItem("refreshToken", newRefreshToken);

            // 元のリクエストを再試行
            error.config.headers.Authorization = `Bearer ${authToken}`;
            return apiClient.request(error.config);
          } catch (refreshError) {
            console.error("リフレッシュトークンの取得に失敗しました", refreshError);
            // 必要に応じてログアウト処理を追加
            localStorage.removeItem("authToken");
            localStorage.removeItem("refreshToken");
            window.location.href = "/login"; // ログイン画面にリダイレクト
          }
        } else {
          console.error("リフレッシュトークンがありません");
          localStorage.removeItem("authToken");
          window.location.href = "/login"; // ログイン画面にリダイレクト
        }
      } else if (errorData.error === "Invalid token") {
        // トークンが無効の場合の処理
        console.error("無効なトークンです。ログアウトします。");
        localStorage.removeItem("authToken");
        localStorage.removeItem("refreshToken");
        window.location.href = "/login"; // ログイン画面にリダイレクト
      } else {
        console.error("認証エラーが発生しました", errorData.error);
        // 他の401エラーに対する対応を追加する場合
      }
    }

    // その他のエラーはそのままスロー
    return Promise.reject(error);
  }
);

export default apiClient;
