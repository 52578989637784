import React, { useEffect, useState } from "react";
import { useAuth } from "@/components/AuthContext";
import { fetchSite, updateSite } from "@/services/dashboardService";

const DashboardProfilePage: React.FC = () => {
  const { currentUser } = useAuth();
  const [profile, setProfile] = useState<SiteProfile | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const siteId = currentUser?.site_id;
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    const loadProfile = async () => {
      try {
        setLoading(true);
        const data = await fetchSite(siteId);
        setProfile(data);
        setName(data.name || "");
        setDescription(data.description || "");
      } catch (err: any) {
        setError("プロフィールの取得に失敗しました");
      } finally {
        setLoading(false);
      }
    };

    loadProfile();
  }, [siteId]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);

    try {
      const updatedProfile = await updateSite(siteId, { name, description });
      setProfile(updatedProfile);
      alert("プロフィールが更新されました");
    } catch (err: any) {
      setError("プロフィールの更新に失敗しました");
    }
  };

  if (loading) return <p>読み込み中...</p>;
  if (error) return <p className="text-red-500">{error}</p>;
  return (
    <div className="container mx-auto">
      <div className="mb-4">
        <h1 className="font-semibold tracking-tight text-xl">店舗情報</h1>
        <div className="text-sm text-muted-foreground">店舗情報を編集します。</div>
      </div>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="name" className="block text-sm font-medium">
            名前
          </label>
          <input
            id="name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            required
          />
        </div>

        <div>
          <label htmlFor="description" className="block text-sm font-medium">
            説明
          </label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows={5}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
          ></textarea>
        </div>

        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
        >
          保存する
        </button>
      </form>
    </div>
  );
}
export default DashboardProfilePage;
