import React, { useState } from "react";

const PointPage: React.FC = () => {
  return (
    <div>
      <h1>PointPage</h1>
    </div>
  );
}
export default PointPage;
