import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import apiClient from "@/lib/apiClient";

const ResetPassowordPage: React.FC = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");

    try {
      console.log("パスワード再設定のリクエストを送信します");
      // const response = await apiClient.post("/sessions", {
      //   email,
      //   password,
      // });

      // // トークンを保存 (例: ローカルストレージに保存)
      // login(response.data.token);
      // alert("ログイン成功");
    } catch (err: any) {
      setError(err.response?.data?.error || "ログインに失敗しました");
    }
  };

  return (
    <section className="relative bg-[url('/hero.jpg')] bg-cover bg-center bg-no-repeat py-20">
      <div className="absolute inset-0 bg-gray-900/40"></div>

      <div className="relative mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
        <Card className="mx-auto max-w-md bg-white shadow-lg rounded-lg">
          <CardHeader className="text-center">
            <CardTitle className="text-2xl font-bold text-gray-800">パスワード再設定</CardTitle>
          </CardHeader>
          <CardContent>
            <form onSubmit={handleSubmit} className="grid gap-6">
              <div className="grid gap-4">
                <Label htmlFor="email">メールアドレス</Label>
                <Input
                  id="email"
                  type="email"
                  placeholder="test@example.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <Button type="submit" className="w-full bg-yellow-400 hover:bg-yellow-500 text-black font-bold">
                パスワード再設定リクエストを送信
              </Button>
              {error && <p className="text-red-500 text-center">{error}</p>}
            </form>
          </CardContent>
        </Card>
      </div>
    </section>
  );
}
export default ResetPassowordPage;
