import React, { useState } from "react";

const OrdersPage: React.FC = () => {
  return (
    <div>
      <h1>Orders</h1>
    </div>
  );
}
export default OrdersPage;
