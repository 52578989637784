import React, { useState } from "react";
import { Link } from "react-router-dom"
import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"

type EventCardProps = {
  uid: string;
  siteId: string;
  name: string;
  description?: string;
  imageUrl: string;
};

const EventCard: React.FC<EventCardProps> = ({ uid, siteId, name, description, imageUrl }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  return (
    <>
      <Link
        to={`/oripa/${siteId}/${uid}`}
        className="group relative block overflow-hidden"
      >
        {/*
        <button
          className="absolute end-4 top-4 z-10 rounded-full bg-white p-1.5 text-gray-900 transition hover:text-gray-900/75"
        >
          <span className="sr-only">Wishlist</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="h-4 w-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
            />
          </svg>
        </button>
        */}

        <img
          src={imageUrl}
          alt={name}
          className="h-64 w-full object-center transition duration-500 group-hover:scale-105 sm:h-72"
        />

        <div className="relative border border-solid border-gray-100 bg-white px-6 pt-2 pb-6">
          <span className="whitespace-nowrap bg-white px-3 py-1.5 text-xs font-medium border-2 border-solid border-gray-300 rounded-full text-gray-700">
            400 C
          </span>

          <div>
            <span id="ProgressLabel" className="sr-only">Loading</span>

            <span
              role="progressbar"
              aria-labelledby="ProgressLabel"
              aria-valuenow={50}
              className="block rounded-full bg-gray-200"
            >
              <span className="block h-4 rounded-full bg-yellow-400 text-center text-[10px]/4" style={{ width: "50%" }}>
              </span>
            </span>
          </div>

          <p className="mt-1.5 text-sm text-gray-700">のこり 24,000/30,000</p>

          <div className="mt-4 grid grid-cols-3 gap-3">
            <button
              className="block w-full rounded bg-black p-4 text-sm font-medium text-white transition hover:scale-105"
              onClick={(e) => {
                e.preventDefault()
                setIsDialogOpen(true)
              }}
            >
              1回ガチャ
            </button>
            <button
              className="block w-full rounded bg-yellow-400 p-4 text-sm font-bold transition hover:scale-105"
              onClick={(e) => {
                e.preventDefault()
                setIsDialogOpen(true)
              }}
            >
              10連ガチャ
            </button>
            <button
              className="block w-full rounded bg-yellow-400 p-4 text-sm font-bold transition hover:scale-105"
              onClick={(e) => {
                e.preventDefault()
                setIsDialogOpen(true)
              }}
            >
              100連ガチャ
            </button>
          </div>
        </div>
      </Link>

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>ガチャを実行しますか？</DialogTitle>
            <DialogDescription>
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button type="submit">ガチャを引く</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EventCard;
