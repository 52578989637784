import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import apiClient from "@/lib/apiClient";

const SignupPage: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setSuccessMessage("");

    try {
      const response = await apiClient.post("/customer/registrations", {
        customer: {
          email,
          password,
          password_confirmation: passwordConfirmation,
        },
      });

      setSuccessMessage(response.data.message);
      setEmail("");
      setPassword("");
      setPasswordConfirmation("");
    } catch (err: any) {
      setError(err.response?.data?.errors?.join(", ") || "エラーが発生しました");
    }
  };

  return (
    <section className="relative bg-[url('/hero.jpg')] bg-cover bg-center bg-no-repeat py-20">
      <div className="absolute inset-0 bg-gray-900/40"></div>

      <div className="relative mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
        <Card className="mx-auto max-w-md bg-white shadow-lg rounded-lg">
          <CardHeader className="text-center">
            <CardTitle className="text-2xl font-bold text-gray-800">会員登録</CardTitle>
          </CardHeader>
          <CardContent>
            <form onSubmit={handleSubmit} className="grid gap-6">
              <div className="grid gap-4">
                <div>
                  <Label htmlFor="email" className="block text-gray-700 font-medium">
                    メールアドレス
                  </Label>
                  <Input
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="example@example.com"
                    className="mt-1 w-full border-gray-300 rounded-lg shadow-sm focus:ring-yellow-400 focus:border-yellow-400"
                    required
                  />
                </div>
                <div>
                  <Label htmlFor="password" className="block text-gray-700 font-medium">
                    パスワード
                  </Label>
                  <Input
                    id="password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="********"
                    className="mt-1 w-full border-gray-300 rounded-lg shadow-sm focus:ring-yellow-400 focus:border-yellow-400"
                    required
                  />
                </div>
                <div>
                  <Label htmlFor="passwordConfirmation" className="block text-gray-700 font-medium">
                    パスワード (確認用)
                  </Label>
                  <Input
                    id="passwordConfirmation"
                    type="password"
                    value={passwordConfirmation}
                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                    placeholder="********"
                    className="mt-1 w-full border-gray-300 rounded-lg shadow-sm focus:ring-yellow-400 focus:border-yellow-400"
                    required
                  />
                </div>
              </div>
              {error && <p className="text-red-500 text-center font-medium">{error}</p>}
              {successMessage && <p className="text-green-500 text-center font-medium">{successMessage}</p>}
              <Button
                type="submit"
                className="w-full bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 rounded-lg shadow-md transition focus:outline-none focus:ring-2 focus:ring-yellow-300"
              >
                会員登録
              </Button>
            </form>
          </CardContent>
        </Card>
      </div>
    </section>
  );
};
export default SignupPage;
