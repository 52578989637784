import React, { useEffect, useState } from "react";
import { useAuth } from "@/components/AuthContext";
import { useNavigate } from "react-router-dom";
import { fetchEvents } from "@/services/dashboardService";
import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableCaption,
} from "@/components/ui/table"

import moment from "moment";

type Event = {
  id: number;
  name: string;
  description: string;
  starts_at: string;
  ends_at: string;
};

const DashboardEventsPage: React.FC = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const siteId = currentUser?.site_id;

  useEffect(() => {
    const loadEvents = async () => {
      try {
        const data = await fetchEvents(siteId);
        setEvents(data);
      } catch (error: any) {
        setError(error.message || "データ取得に失敗しました");
      } finally {
        setLoading(false);
      }
    };

    loadEvents();
  }, [siteId]);

  return (
    <div className="container mx-auto">
      <div className="mb-4">
        <h1 className="font-semibold tracking-tight text-xl">イベント一覧</h1>
        <div className="text-sm text-muted-foreground">オリパイベントの一覧を表示します。</div>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>イベント名</TableHead>
              <TableHead>ステータス</TableHead>
              <TableHead>公開期間</TableHead>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {events.length ? (
              events.map((event) => (
                <TableRow key={event.id}>
                  <TableCell>{event.name}</TableCell>
                  <TableCell>
                    <Badge variant="outline" className="ml-auto sm:ml-0">
                      {event.status}
                    </Badge>
                  </TableCell>
                  <TableCell>
                    {moment(event.starts_at).format("YYYY-MM-DD")} ~ {moment(event.ends_at).format("YYYY-MM-DD")}
                  </TableCell>
                  <TableCell>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => navigate(`/dashboard/events/${event.id}`)}
                    >
                      詳細
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} className="text-center">
                  {loading ? "Loading..." : error || "No data"}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
export default DashboardEventsPage;
