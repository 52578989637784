import React, { useState } from "react";

const DashboardOrdersPage: React.FC = () => {
  return (
    <div className="container mx-auto">
      <div className="mb-4">
        <h1 className="font-semibold tracking-tight text-xl">注文一覧</h1>
        <div className="text-sm text-muted-foreground">注文一覧を表示します。</div>
      </div>
    </div>
  );
};
export default DashboardOrdersPage;
