import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchEventDetail, EventDetail } from "@/services/customerService";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";

const EventsDetailsPage: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>(); // siteIdとeventIdを取得
  const [event, setEvent] = useState<EventDetail | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    const loadEventDetail = async () => {
      try {
        if (!eventId) throw new Error("Missing eventId");
        const data = await fetchEventDetail(eventId);
        setEvent(data);
      } catch (err: any) {
        setError(err.message || "Failed to load event details");
      } finally {
        setLoading(false);
      }
    };

    loadEventDetail();
  }, [eventId]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!event) return <p>Event not found</p>;

  return (
    <>
      <div className="mx-auto h-screen max-w-7xl py-2 flex items-start justify-center">
        <div className="relative w-full h-3/4 max-w-md">
          <div className="fixed top-15 left-1/2 transform -translate-x-1/2 w-full max-w-md">
            <img
              src="/event_thumbnail_sample.png"
              alt="Event Thumbnail"
              className="object-cover w-full h-full rounded-lg"
            />
          </div>

          <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-transparent to-black mt-[80vh]" >
            <img
              src="/event_detail_sample.png"
              alt="Event Detail"
              className="object-cover w-full h-full rounded-lg"
            />
          </div>
        </div>

        <div className="fixed bottom-0 left-1/2 transform -translate-x-1/2 w-full max-w-lg border border-solid border-gray-100 bg-white px-6 pt-4 pb-6 rounded-lg shadow-lg">
          <span className="whitespace-nowrap bg-white px-3 py-1.5 text-xs font-medium border-2 border-solid border-gray-300 rounded-full text-gray-700">
            {event.name}
          </span>

          <div className="mt-2">
            <span id="ProgressLabel" className="sr-only">Loading</span>

            <span
              role="progressbar"
              aria-labelledby="ProgressLabel"
              aria-valuenow={50}
              className="block rounded-full bg-gray-200"
            >
              <span className="block h-4 rounded-full bg-yellow-400 text-center text-[10px]/4" style={{ width: "50%" }}>
              </span>
            </span>
          </div>

          <p className="mt-1.5 text-sm text-gray-700">のこり 24,000/30,000</p>

          <div className="mt-4 grid grid-cols-3 gap-3">
            <button
              className="block w-full h-12 rounded bg-black p-2 text-sm font-medium text-white transition hover:scale-105"
              onClick={() => setIsDialogOpen(true)}
            >
              1回ガチャ
            </button>
            <button
              className="block w-full h-12 rounded bg-yellow-400 p-2 text-sm font-bold transition hover:scale-105"
              onClick={() => setIsDialogOpen(true)}
            >
              10連ガチャ
            </button>
            <button
              className="block w-full h-12 rounded bg-yellow-400 p-2 text-sm font-bold transition hover:scale-105"
              onClick={() => setIsDialogOpen(true)}
            >
              100連ガチャ
            </button>
          </div>
        </div>
      </div>

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>ガチャを実行しますか？</DialogTitle>
            <DialogDescription />
          </DialogHeader>
          <DialogFooter>
            <Button type="submit">ガチャを引く</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EventsDetailsPage;
