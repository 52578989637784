export default function Footer() {
  return (
    <footer className="bg-gray-50">
      <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
        <div className="lg:grid lg:grid-cols-2">
          <div
              className="border-b border-gray-100 py-8 lg:order-last lg:border-b-0 lg:border-s lg:py-16 lg:ps-16"
              >
              <div className="block lg:hidden">
                <a className="flex items-center gap-2">
                  <svg width="28" height="28" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="100" cy="100" r="95" fill="#f2f2f2" stroke="#333" strokeWidth="5" />
                    <rect x="60" y="40" width="80" height="120" rx="10" ry="10" fill="#ffffff" stroke="#333" strokeWidth="5" />
                    <rect x="40" y="60" width="80" height="120" rx="10" ry="10" fill="#e2e2e2" stroke="#333" strokeWidth="5" />
                    <circle cx="100" cy="100" r="20" fill="#ff6f61" />
                    <circle cx="100" cy="90" r="10" fill="#ff6f61" />
                    <circle cx="100" cy="110" r="10" fill="#ff6f61" />
                    <text x="70" y="150" fontFamily="Arial" fontSize="30" fill="#333">A</text>
                    <text x="120" y="150" fontFamily="Arial" fontSize="30" fill="#333">♠</text>
                  </svg>
                  <h3 className="text-lg font-semibold">JOYBASE</h3>
                </a>
              </div>

              <div className="mt-8 space-y-4 lg:mt-0">
                <span className="hidden h-1 w-10 rounded bg-teal-500 lg:block"></span>

                <div>
                  <h2 className="text-2xl font-medium text-gray-900">店舗開設のご相談はこちら</h2>

                  <p className="mt-4 max-w-lg text-gray-500">
                  ショップ開設するためのご相談やお問い合わせ<br />はこちらからお気軽にお問い合わせください。
                  </p>
                </div>

                <div className="mt-6 text-center">
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSeLmjsHbwfYvEbMo4sOC7Sr7SFC6oeuogsXA6Ts4cd_pLdWpA/viewform?usp=sf_link"
                    target="_blank"
                    className="mt-1 rounded bg-teal-500 px-6 py-3 text-sm font-bold text-white transition-none hover:bg-teal-600"
                   >
                    お問い合わせ
                  </a>
                </div>
              </div>
          </div>

          <div className="py-8 lg:py-16 lg:pe-16">
            <div className="hidden lg:block">
              <a className="flex items-center gap-2">
                <svg width="28" height="28" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="100" cy="100" r="95" fill="#f2f2f2" stroke="#333" strokeWidth="5" />
                  <rect x="60" y="40" width="80" height="120" rx="10" ry="10" fill="#ffffff" stroke="#333" strokeWidth="5" />
                  <rect x="40" y="60" width="80" height="120" rx="10" ry="10" fill="#e2e2e2" stroke="#333" strokeWidth="5" />
                  <circle cx="100" cy="100" r="20" fill="#ff6f61" />
                  <circle cx="100" cy="90" r="10" fill="#ff6f61" />
                  <circle cx="100" cy="110" r="10" fill="#ff6f61" />
                  <text x="70" y="150" fontFamily="Arial" fontSize="30" fill="#333">A</text>
                  <text x="120" y="150" fontFamily="Arial" fontSize="30" fill="#333">♠</text>
                </svg>
                <h3 className="text-lg font-semibold">JOYBASE</h3>
              </a>
            </div>

            <div className="mt-8 grid grid-cols-1 gap-8 sm:grid-cols-3">
              <div>
                <p className="font-medium text-gray-900">サポート</p>

                <ul className="mt-6 space-y-4 text-sm">
                  <li>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSeLmjsHbwfYvEbMo4sOC7Sr7SFC6oeuogsXA6Ts4cd_pLdWpA/viewform?usp=sf_link" className="text-gray-700 transition hover:opacity-75"> お問い合わせ </a>
                  </li>
                </ul>
              </div>

              <div>
                <p className="font-medium text-gray-900">企業情報</p>

                <ul className="mt-6 space-y-4 text-sm">
                  <li>
                    <a href="https://mixpeanuts.llc/" target="_blank" className="text-gray-700 transition hover:opacity-75"> 運営会社 </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="mt-8 border-t border-gray-100 pt-8">
              <ul className="flex flex-wrap gap-4 text-xs">
                <li>
                  <a href="/terms" className="text-gray-500 transition hover:opacity-75"> 利用規約 </a>
                </li>

                <li>
                  <a href="/trade-law" className="text-gray-500 transition hover:opacity-75"> 特定商取引法に基づく表記 </a>
                </li>

                <li>
                  <a href="/privacy" className="text-gray-500 transition hover:opacity-75"> プライバシーポリシー </a>
                </li>
              </ul>

              <p className="mt-8 text-xs text-gray-500">&copy; 2024. JOYBASE. All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
