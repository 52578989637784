import React from "react";
const DashboardIndexPage: React.FC = () => {
  return (
    <div>
      <main className="mx-auto max-w-screen-xl bg-gray-100 px-8 py-4">
        ダッシュボード
      </main>
    </div>
  );
};

export default DashboardIndexPage;
