import React, { useEffect, useState } from "react";

const DashboardSettingsPage: React.FC = () => {
  return (
    <div className="container mx-auto">
      <div className="mb-4">
        <h1 className="font-semibold tracking-tight text-xl">各種設定</h1>
      </div>
    </div>
  );
}
export default DashboardSettingsPage;
