import React, { useState } from "react";

const TradeLawPage: React.FC = () => {
  return (
    <section className="bg-white">
      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16 flex items-center justify-center ">
        <div className="max-w-xl lg:max-w-3xl">
          <h1 className="mt-6 text-2xl font-bold text-gray-900 sm:text-2xl">特定商取引法に基づく表記</h1>
        </div>
      </div>
    </section>
  );
}
export default TradeLawPage;
