import React, { useEffect, useState } from "react";
import { useAuth } from "@/components/AuthContext";
import { fetchProducts } from "@/services/dashboardService";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableCaption,
} from "@/components/ui/table"

type Product = {
  id: number;
  product_type: string;
  profile: {
    name: string;
    description: string;
  };
  stocks: {
    status: string;
  }[];
};

const DashboardProductsPage: React.FC = () => {
  const { currentUser } = useAuth();
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const siteId = currentUser?.site_id;

  useEffect(() => {
    const loadProducts = async () => {
      try {
        const data = await fetchProducts(siteId);
        setProducts(data);
      } catch (error: any) {
        setError(error.message || "データ取得に失敗しました");
      } finally {
        setLoading(false);
      }
    };

    loadProducts();
  }, [siteId]);

  return (
    <div className="container mx-auto">
      <div className="mb-4">
        <h1 className="font-semibold tracking-tight text-xl">商品一覧</h1>
        <div className="text-sm text-muted-foreground">オリパイベントの一覧を表示します。</div>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>名前</TableHead>
              <TableHead>概要</TableHead>
              <TableHead>種別</TableHead>
              <TableHead>在庫状況</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {products.length ? (
              products.map((product) => (
                <TableRow key={product.id}>
                  <TableCell>{product.profile.name}</TableCell>
                  <TableCell>{product.profile.description}</TableCell>
                  <TableCell>{product.product_type}</TableCell>
                  <TableCell>{product.stocks[0].status}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} className="text-center">
                  {loading ? "Loading..." : error || "No data"}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
export default DashboardProductsPage;
