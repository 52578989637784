import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "@/components/AuthContext";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import apiClient from "@/lib/apiClient";

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { login } = useAuth();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");

    try {
      const response = await apiClient.post("/sessions", { email, password });

      // トークンとユーザー情報を保存
      login({ token: response.data.token, user: response.data.user });
      alert("ログイン成功");
    } catch (err: any) {
      setError(err.response?.data?.error || "ログインに失敗しました");
    }
  };

  return (
    <section className="relative bg-[url('/hero.jpg')] bg-cover bg-center bg-no-repeat py-20">
      <div className="absolute inset-0 bg-gray-900/40"></div>

      <div className="relative mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
        <Card className="mx-auto max-w-md bg-white shadow-lg rounded-lg">
          <CardHeader className="text-center">
            <CardTitle className="text-2xl font-bold text-gray-800">ログイン</CardTitle>
          </CardHeader>
          <CardContent>
            <form onSubmit={handleSubmit} className="grid gap-6">
              <div className="grid gap-4">
                <Label htmlFor="email">メールアドレス</Label>
                <Input
                  id="email"
                  type="email"
                  placeholder="test@example.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="grid gap-4">
                <Label htmlFor="password">パスワード</Label>
                <Input
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  placeholder="********"
                />
              </div>
              <Link to="/resets_password" className="ml-auto inline-block text-sm underline">
                パスワードを忘れた方はこちら
              </Link>
              <Button type="submit" className="w-full bg-yellow-400 hover:bg-yellow-500 text-black font-bold">
                ログイン
              </Button>
              {error && <p className="text-red-500 text-center">{error}</p>}
            </form>
          </CardContent>
        </Card>
      </div>
    </section>
  );
};
export default LoginPage;
