// app/frontend/services/customerService.ts
import apiClient from "@/lib/apiClient";

type Customer = {
  id: number;
  name: string;
  email: string;
  phone: string;
};

export const fetchCustomers = async (siteId: number): Promise<Customer[]> => {
  const response = await apiClient.get<Customer[]>(`/dashboard/sites/${siteId}/customers`);
  return response.data;
};

export type Site = {
  id: number;
  uid: string; // UID を明示的に追加
  name: string;
  subdomain: string;
};

export type EventDetail = {
  id: number;
  uid: string; // UID を明示的に追加
  name: string;
  description: string;
  starts_at: string;
  ends_at: string;
  products: Array<{ id: number; name: string }>;
};

export type Event = {
  id: number;
  uid: string; // UID を含む
  name: string;
  starts_at: string;
  ends_at: string;
};

export type Category = {
  id: number;
  name: string;
  slug: string;
};

export type Tag = {
  id: number;
  name: string;
  slug: string;
};

export const fetchSites = async (): Promise<Site[]> => {
  const response = await apiClient.get<Site[]>("/customer/sites");
  return response.data;
};

export const fetchSiteDetail = async (siteId: number): Promise<Site> => {
  const response = await apiClient.get<Site>(`/customer/sites/${siteId}`);
  return response.data;
};

export const fetchEvents = async (
  categorySlug?: string,
  tagSlugs?: string[]
): Promise<Event[]> => {
  let url = `/customer/events`;
  const params = new URLSearchParams();

  if (categorySlug) params.append("category_slug", categorySlug);
  if (tagSlugs && tagSlugs.length > 0) params.append("tag_slugs", tagSlugs.join(","));

  if (params.toString()) url += `?${params.toString()}`;

  const response = await apiClient.get<Event[]>(url);
  return response.data;
};

export const fetchEventDetail = async (uid: string): Promise<EventDetail> => {
  const response = await apiClient.get<EventDetail>(`/customer/events/${uid}`);
  return response.data;
};


export const fetchSiteEvents = async (
  siteId: number,
  categorySlug?: string,
  tagSlugs?: string[]
): Promise<Event[]> => {
  let url = `/customer/sites/${siteId}/events`;
  const params = new URLSearchParams();

  if (categorySlug) params.append("category_slug", categorySlug);
  if (tagSlugs && tagSlugs.length > 0) params.append("tag_slugs", tagSlugs.join(","));

  if (params.toString()) url += `?${params.toString()}`;

  const response = await apiClient.get<Event[]>(url);
  return response.data;
};

export const fetchSiteCategories = async (siteId: number): Promise<Category[]> => {
  const response = await apiClient.get<Category[]>(`/customer/sites/${siteId}/categories`);
  return response.data;
};

export const fetchSiteTags = async (siteId: number): Promise<Tag[]> => {
  const response = await apiClient.get<Tag[]>(`/customer/sites/${siteId}/tags`);
  return response.data;
};

export type HomeAddress = {
  id: number;
  name: string;
  postal_code: string;
  address_line1: string;
  address_line2?: string;
  phone_number: string;
  default: boolean;
};

export const fetchHomeAddresses = async (): Promise<HomeAddress[]> => {
  const response = await apiClient.get<HomeAddress[]>("/customer/home_addresses");
  return response.data;
};

export const createHomeAddress = async (address: Omit<HomeAddress, "id">): Promise<HomeAddress> => {
  const response = await apiClient.post<HomeAddress>("/customer/home_addresses", address);
  return response.data;
};

export const updateHomeAddress = async (id: number, address: Partial<HomeAddress>): Promise<HomeAddress> => {
  const response = await apiClient.put<HomeAddress>(`/customer/home_addresses/${id}`, address);
  return response.data;
};

export const deleteHomeAddress = async (id: number): Promise<void> => {
  await apiClient.delete(`/customer/home_addresses/${id}`);
};
