import apiClient from "@/lib/apiClient";

type Event = {
  id: number;
  name: string;
  description: string;
  starts_at: string;
  ends_at: string;
  category_id: number | null;
  category?: {
    id: number;
    name: string;
    slug: string;
  };
  status: "draft" | "published" | "archived";
  file_urls: string[];
};

export const fetchEvents = async (siteId: number): Promise<Event[]> => {
  const response = await apiClient.get<Event[]>(`/dashboard/sites/${siteId}/events`);
  return response.data;
};

export const fetchEvent = async (siteId: number, eventId: number): Promise<Event> => {
  const response = await apiClient.get<Event>(`/dashboard/sites/${siteId}/events/${eventId}`);
  return response.data;
};

export const updateEvent = async (
  siteId: number,
  eventId: number,
  updatedEvent: Partial<Event>
): Promise<Event> => {
  const response = await apiClient.put<Event>(
    `/dashboard/sites/${siteId}/events/${eventId}`,
    updatedEvent
  );
  return response.data;
};

type Category = {
  id: number;
  name: string;
  slug: string;
};

export const fetchCategories = async (siteId: number): Promise<Category[]> => {
  const response = await apiClient.get<Category[]>(`/dashboard/sites/${siteId}/categories`);
  return response.data;
};

type ProductProfile = {
  name: string;
  description: string;
};

type ProductStock = {
  status: string;
};

type Product = {
  id: number;
  product_type: string;
  profile: ProductProfile;
  stocks: ProductStock[];
};

export const fetchProducts = async (siteId: number): Promise<Product[]> => {
  const response = await apiClient.get<Product[]>(`/dashboard/sites/${siteId}/products`);
  return response.data;
};

// アップロードされたファイルの情報
type AttachmentResponse = {
  message: string;
  file_urls: string[];
};

// ファイルをアップロード
export const uploadAttachments = async (
  attachableType: string,
  attachableId: number,
  files: File[]
): Promise<AttachmentResponse> => {
  const formData = new FormData();
  formData.append("attachable_type", attachableType);
  formData.append("attachable_id", String(attachableId));
  files.forEach((file) => formData.append("files[]", file));

  const response = await apiClient.post<AttachmentResponse>(
    "/dashboard/attachments",
    formData,
    { headers: { "Content-Type": "multipart/form-data" } }
  );

  return response.data;
};

// 特定のファイルを削除
export const deleteAttachment = async (
  attachableType: string,
  attachableId: number,
  fileId?: string
): Promise<{ message: string }> => {
  const payload: { attachable_type: string; attachable_id: number; file_id?: string } = {
    attachable_type: attachableType,
    attachable_id: attachableId,
  };

  if (fileId) {
    payload.file_id = fileId;
  }

  const response = await apiClient.delete<{ message: string }>("/dashboard/attachments", {
    data: payload,
  });

  return response.data;
};

export type Site = {
  id: number;
  name: string;
  description: string;
  subdomain: string;
};

export const fetchSite = async (siteId: number): Promise<Site> => {
  const response = await apiClient.get<Site>(`/dashboard/sites/${siteId}`);
  return response.data;
};

export const updateSite = async (
  siteId: number,
  siteData: Partial<Site>
): Promise<Site> => {
  const response = await apiClient.put<Site>(`/dashboard/sites/${siteId}`, siteData);
  return response.data;
};
